import React from 'react';
import './HomePage.css'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
    const { t } = useTranslation()

    return (
      <div className="home-page">
        <img src={require('./assets/SLB Grey Black.png')} alt="Band Logo" className="logo" />
        <h1 className="title">{ t('homePage.comingSoon') }...</h1>
      </div>
    );
  };
  
  export default HomePage;
