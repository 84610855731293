import React from 'react';
import HomePage from './home-page/HomePage';
import LanguageSelector from './language-selector/LanguageSelector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your language files
import enTranslation from './locales/en.json';
import huTranslation from './locales/hu.json';

// Initialize i18next
i18n.use(initReactI18next).init({
  lng: 'en', // Set the default language
  resources: {
    en: {
      translation: enTranslation,
    },
    hu: {
      translation: huTranslation,
    },
  },
});

const App = () => {
  return (
    <div>
      <LanguageSelector />
      <HomePage />
    </div>
  );
};

export default App;